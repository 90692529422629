import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import {
  EcomComponent,
  addComponents,
  installCartIcon,
} from '@wix/ecom-platform-sdk';
import { removeBookCheckoutPageOnEcom } from '../pages-panel-actions';
import { addBookingCheckoutPage } from '../pages-actions';
import {
  ecomRolloutButNotSite,
  isEcomCartInstalled,
} from '../editor-sdk-actions';
import { EditorScriptApi } from '../../api/api';
import { isExperimentEnabled } from '../experiments';
import { experiments, FedopsInterations } from '../../constants';
import {
  updateIsCartEnabledBusinessProperty,
  getIsCartEnabledBusinessProperty,
} from '../migrate-actions';
import {
  openCartSuccessfullyInstalledModal,
  openEcomMigrationProposalModal,
  openEcomHistoryBlockModal,
} from '../migration-modals';
import { migrateAction, MigrateAction } from '../../migration';
import { Translations } from '../translations';

interface EcomInstallationParams {
  isFirstInstall: boolean;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
  appToken?: string;
  editorTranslation: Translations;
  fedopsLogger: IFedOpsLogger;
  migrationActionInput?: MigrateAction;
  instance: string;
  locale?: any;
}

interface MigrateCartParams {
  editorSdk: EditorSDK;
  editorScriptApi: EditorScriptApi;
  instance: string;
}

interface InstallCart {
  editorSdk: EditorSDK;
  editorScriptApi: EditorScriptApi;
  instance: string;
  editorOptions: EditorReadyOptions;
  fedopsLogger: IFedOpsLogger;
  locale?: any;
}

export const markAsEcom = async ({
  isEcomReady,
  editorScriptApi,
  editorSdk,
  appToken,
}): Promise<boolean> => {
  if (!isEcomReady) {
    const isReady = await editorScriptApi.setEcomCompatible();
    if (isReady) {
      await removeBookCheckoutPageOnEcom(
        editorSdk,
        appToken,
        editorScriptApi,
      );
    } else {
      await addBookingCheckoutPage(editorSdk, appToken);
    }
    return isReady;
  }
};

export const skipEcomInstallationAction = ({
  _editorSDK,
  editorScriptApi,
  instance,
  locale,
  editorTranslation,
  fedopsLogger,
  migrationActionInput,
}: EcomInstallationParams) => {
  return async () => {
    const isEcomMigrationProposalModal = await isExperimentEnabled(
      experiments.ECOM_MIGRATION_MODAL_ENABLED,
    );

    if (
      isEcomMigrationProposalModal &&
      !(await editorScriptApi.getIsBookingsOnEcom()) &&
      (await editorScriptApi.shouldMigrateEcom())
    ) {
      openEcomMigrationProposalModal(
        _editorSDK,
        editorScriptApi,
        locale,
        fedopsLogger,
        instance,
        editorTranslation,
        { ...migrationActionInput, isSelfMigration: true },
      );
      return true;
    }

    return false;
  };
};

export const requiredEcomComponentsAction = (): (() => Promise<
  EcomComponent[]
>) => {
  return async () => {
    const isCartEnabledFT = await isExperimentEnabled(experiments.CART_ENABLED);

    if (isCartEnabledFT) {
      return [
        EcomComponent.CHECKOUT,
        EcomComponent.THANK_YOU_PAGE,
        EcomComponent.CART,
        EcomComponent.CART_ICON,
      ];
    } else {
      return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE];
    }
  };
};

export const installEcomCartIcon = async ({
  editorSdk,
  editorOptions,
  editorScriptApi,
  locale,
  instance,
}: InstallCart) => {
  if (editorOptions?.appUrlQueryParams?.isCartFirstInstall) {
    if (!editorOptions?.firstInstall) {
      await installCartIcon(editorSdk);
    }

    openCartSuccessfullyInstalledModal(
      editorSdk,
      editorScriptApi,
      locale,
      instance,
    );
  }
};

export const installCart = async ({
  editorOptions,
  editorScriptApi,
  instance,
  locale,
  editorSdk,
  fedopsLogger
}: InstallCart) => {
  const isCartEnabledFT = await isExperimentEnabled(experiments.CART_ENABLED);

  if (isCartEnabledFT) {
    fedopsLogger.interactionStarted(FedopsInterations.InstallCart);
    const isCartEnabled: boolean | undefined = await getIsCartEnabledBusinessProperty(editorScriptApi, instance);

    // undefined meaning this flag never initiate, and we should initiate for first install to true
    if (editorOptions?.firstInstall && isCartEnabled === undefined) {
      await updateIsCartEnabledBusinessProperty(
        editorScriptApi,
        instance,
        true,
      );
    }

    await installEcomCartIcon({
      editorOptions,
      editorScriptApi,
      instance,
      locale,
      editorSdk,
      fedopsLogger
    });
    fedopsLogger.interactionEnded(FedopsInterations.InstallCart);
  }
};

export const validateAndFixEcomSite = async ({
  isFirstInstall,
  _editorSDK,
  editorScriptApi,
  appToken,
  locale,
  fedopsLogger,
  migrationActionInput,
}: EcomInstallationParams) => {
  if (!isFirstInstall && migrationActionInput) {
    const isEcomRolloutButNotSite = await ecomRolloutButNotSite(
      _editorSDK,
      editorScriptApi,
      appToken!,
    );

    if (isEcomRolloutButNotSite) {
      try {
        fedopsLogger.interactionStarted(FedopsInterations.FixSite);
        await migrateAction(migrationActionInput);
        fedopsLogger.interactionEnded(FedopsInterations.FixSite);
      } catch (e) {
        openEcomHistoryBlockModal(
          _editorSDK,
          editorScriptApi,
          locale,
          migrationActionInput.instance,
        );
      }
    }
  }
};

export const migrateCart = async ({
  editorSdk,
  editorScriptApi,
  instance,
}: MigrateCartParams) => {
  const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();

  if (!isBookingRollout) {
    throw new Error('migrateCart - Site are not Rollout');
  }

  try {
    await updateIsCartEnabledBusinessProperty(editorScriptApi, instance, true);
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } | migrateCart - updateIsCartEnabledBusinessProperty - errorCode: 8ad89f0b-96c9-4538-9158-329418318132`;
    throw new Error(errorMessage);
  }

  const isCartInstalled = !!(await isEcomCartInstalled(editorSdk));

  if (!isCartInstalled) {
    try {
      await addComponents(editorSdk, [EcomComponent.CART]);
    } catch (e) {
      const errorMessage = `${
        e?.message ? e.message : JSON.stringify(e)
      } | migrateCart - addComponents - errorCode: 4589f0b-96c9-4538-9158-329418398312`;
      throw new Error(errorMessage);
    }
  }

  console.log('Cart Migration Success');
};
